<template>
  <div class="infoNavigation">
    <div class="box">
      <template v-for="(item,index) in list" :key="index">
        <van-cell
          @click="JumpLink(item)"
          :title="item.processName"
          :value-class="!item.processState ? '' : 'blueText'"
          is-link
          :value="item.val"
        />
      </template>

      <div class="base-footer-text">
        <van-checkbox
          icon-size="20px"
          checked-color="#8486F8"
          v-model="checked"
        />
        <div class="base-text-p" @click="lookProtocol">查看授信相关协议</div>
      </div>
      <div @click="handleSubmit" :class="checked ? 'subBtn' : 'subBtn1'">
        同意协议并提交
      </div>

      <div class="i-back">
        <span @click="handleJumpIndex">回到首页</span>
      </div>

    </div>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import {Jump} from '@/utils/routh'
import {isEmptyObj} from '@/utils/index'
import API from '@/server/api'
import {Toast} from 'vant'
import {useRoute, useRouter} from 'vue-router'

export default {
  setup() {
    const Route = useRoute()
    const Router = useRouter()
    const state = reactive({
      isLoading: false,
      list: [],
      canSubmit: false,

      creditNo: '',
      checked: false,
      key: null
    })

    // 获取认证列表状态
    const init = async () => {
      try {
        const result = await API.getUserStatus({creditNo: state.creditNo})
        if (result.success) {
          result.data.forEach(item => {
            switch (item.processId) {
              case 1:
                item.val = '未认证'
                if (item.processState) item.val = '已认证'
                break
              case 2:
              case 3:
                item.val = '未填写'
                if (item.processState) item.val = '已填写'
                break
              case 4:
                item.val = '未上传'
                if (item.processState) item.val = '已上传'
                break
            }
          })

          state.list = result.data

          let bool = true
          result.data.forEach(item => {
            if (item.processState === 0) bool = false
          })
          state.canSubmit = bool
        }
      } catch (err) {
        console.log(err)
      }
    }

    // 提交
    const handleSubmit = async () => {
      if (!state.canSubmit) {
        Toast('请先完成所有项目，在提交!')
        return
      }
      if (!state.checked) {
        Toast('请阅读并同意协议!')
        return
      }
      let res
      console.log(state.key, 'key')
      switch (state.key) {
        case '1':
          try {
            res = await API.gotoLoan({
              creditNo: state.creditNo,
              jsonObject: {}
            })

            if (res.success) {
              Toast('提交成功')
              setTimeout(function () {
                Router.replace('/index')
              }, 1000)
            }
          } catch (err) {
            Toast(err.message)
            console.log(err.message)
          }

          break
        case '2':
          try {
            res = await API.subAddMoney()

            if (res.success) {
              Toast('提交成功')
              localStorage.setItem('keys', '')
              setTimeout(function () {
                Router.replace('/index')
              }, 1000)
            }
          } catch (err) {
            Toast(err.message)
            console.log(err.message)
          }
          break
      }
    }

    // 跳转到协议列表
    const lookProtocol = () => {
      Jump('/loanProtocol', {num: 4})
    }

    onMounted(() => {
      const newKey = localStorage.getItem('keys')
      if (newKey) state.key = newKey

      if (!isEmptyObj(Route.query)) {
        const {key} = Route.query
        state.key = key
      }

      state.creditNo = localStorage.getItem('creditNo')
      init()
    })

    // 链接跳转
    const JumpLink = async (item) => {
      console.log(item, 'item')

      switch (item.processId) {
        case 1:
          if (!item.processState) {
            try {
              const res = await API.getliveToken()
              if (res.success) {
                const {bizToken} = res.data
                // console.log(`https://api.megvii.com/faceid/lite/do?token=${bizToken}`,'url')
                window.location.href = `https://api.megvii.com/faceid/lite/do?token=${bizToken}`
              }
            } catch (err) {
              Toast(err.message)
              console.log(err)
            }
          }
          break
        case 2:
          if (!item.processState) {
            Jump('/immediateStaging', {
              key: 0,
              creditType: 'NORMAL_CREDIT',
              edit: true
            })
          } else if (item.memo === 'NORMAL_CREDIT') {
            Jump('/immediateStaging', {
              key: 1,
              creditType: item.memo,
              edit: true
            })
          } else if (item.memo === 'ADD_CREDIT') {
            Jump('/immediateStaging', {
              key: 1,
              creditType: item.memo,
              edit: true
            })
          }
          // Jump(`/immediateStaging`, {key: 0})
          break
        case 3:
          if (!item.processState) {
            Jump('/baseInfo', {key: 0})
          } else if (item.processState === -1 || item.processState === 1) {
            Jump('/baseInfo', {key: 0})
          }
          break
        case 4:
          if (!item.processState) {
            Jump('/uploadImg')
          } else if (item.processState === -1 || item.processState === 1) {
            Jump('/uploadImg')
          }
          break
      }
    }

    // 回到首页
    const handleJumpIndex = () => {
      Router.replace('/index')
    }

    return {
      ...toRefs(state),

      lookProtocol,

      handleSubmit,
      JumpLink,
      handleJumpIndex
    }
  }
}
</script>
